
.footer {
    width: 100%;
    height: 10px;
    /* background-color: #121619; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
  }
  
  .socialMedia svg {
    color: rgb(0, 0, 0);
    margin: 10px;
    font-size: 30px;
    cursor: pointer;
  }
  
  .socialMedia svg:hover{
    color:#df1b5a;
  }
  .footer p {
    color: rgb(3, 0, 0);
  }