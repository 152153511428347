/* navBar.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.header {
  width: 100%;
  background-color: transparent;
  color: #fff;
  text-align: left;

  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  overflow-x: hidden;
  z-index: 5;
  transition: background-color 0.3s ease;
  background-color: rgb(0, 0, 0);

}

.menu-icon {
  display: inline-block;
  cursor: pointer;
  
}

.nav_bar {
  list-style: none;
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.nav_bar.active {
  transform: translateX(0);
}

.nav_bar li {
  margin: 10px 20px 0 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem; /* Adjust font size */
  font-weight: 300;
  text-transform: uppercase;
}

.nav_bar li a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.nav_bar li a:hover {
  color:rgb(220, 20, 20);
}

.navbar-logo img {
  height: 40px; 
  float:right;
  margin-right:20px;
  border-radius: 5px;
}

.navbar-logo {
  margin: 0px ; /* Adjust margin */
}

.menu-icon {
   display: none;
}

.sidebar-icon {
  display: none;
}
.header.scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Adjust background color as needed */
}
.navbar-logo.scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Adjust background color as needed */
}

@media (max-width: 768px){
  .header {
    padding: 0 1rem;
  }

  .nav_bar {
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50%; 
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    align-items: flex-start; 
  }
  
  .nav_bar li {
    padding: 1rem 1rem;
  }

  .menu_icon {
    display: block;
  }

  .nav_bar.active {
    transform: translateX(0);
  }
}

@media (max-width: 568px) {
  .header {
    padding: 1rem 1rem;
  }

  .nav_bar {
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50%; 
    background-color: black;
    flex-direction: column;
    align-items: flex-start; 
  }

  .menu-icon {
    display: block;
    border: 2px solid #fff;
    padding: 2px;
    width:5%;
    height:6%;
  }

  .nav_bar li {
    padding: 1rem 1rem;
  }

  .nav_bar.active {
    transform: translateX(0);
  }

  .navbar-logo img{
    float:right;
    width:80%;
    height:30px;
    position:relative;
    left: 20px;
  
  }
}
