body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.App {
  text-align: center;
}
