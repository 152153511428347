.bc-container {
    padding: 60px;
  }
  
  .bc-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .bc-card {
    width: calc(50% - 20px);
    max-width: 400px;
    max-height: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
  }
  
  .bc-card-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .bc-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    text-align: left;
  }
  
  .bc-posted-on {
    text-align: left;
    font-style: italic;
    margin-bottom: 5px;
  }
  
  .bc-title {
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    .bc-card {
      width: 100%;
    }
    
    .bc-posted-on {
      font-size: small;
    }
  }
  